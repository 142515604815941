// All modules
main .modules {
    padding: 40px 0;
    background-size: cover;
    background-repeat: no-repeat;
    background-position: center;
    
}
.open_content, .banner_image, .call_to_action, .daily_deals, 
.deal_accordion, .featured_item_content, .forward_path, .image_gallery, .upcoming_events{
  .mod-cont{
      @extend .container;
    }
}

//HERO BANNER
.slideshow-image {
  position: absolute;
  width: 100%;
  height: 100%;
  background: no-repeat 50% 50%;
  background-size: cover;
}

.modules.hero_banner{

  @include media-breakpoint-up(lg){
      margin-top: -74px;
    }
    @include media-breakpoint-up(xl){
      margin-top: -50px;
    }
  padding: 0px 0;
  .hero-wrapper{
    position: relative;
    overflow: hidden;
    background-size: cover;
    background-repeat: no-repeat;
    background-position: center;
  }
  .hero-content{
    position: relative;
    z-index: 1;
    text-align: center;
    background: rgba(255,255,255, 0.7);
    padding: 80px 5%;
    margin: 150px auto 100px;
    max-width: 1200px;
    width: 100%;
    h1{
        font-size: 2.5em;
        @include media-breakpoint-up(lg) {  
          font-size: 3.5em;    
        }
    }
    .btn{
      margin: 50px 10px 0;
    }
  }
  .image-scale{
    display: none;
    @include media-breakpoint-up(xl) {  
      position: absolute;
      display: block;
      top: 0; 
      bottom: 0;
      right: 0;
      left: 0;
      z-index: 0;
      margin: auto;
      width: 110%;
      height: auto;
      animation-name: kenburns;
      animation-timing-function: linear;
      animation-iteration-count: infinite;
      animation-duration: $total-time;
      opacity: 1;
      transform: scale($scale-base-1);
    }
  }
}

//FORWARDPATH
.modules.forward_path{
  background: $gray-light; 
  padding: 30px 0 70px;
  .fw-header{
      text-align: center;
      position: relative;
      color: $gray;
      font-size: 1.3em;
      margin: 35px 0;
      span{
        background: $gray-light;
        display: inline-block;
        padding: 0 17px;
        position: relative;
        z-index: 2;
      }
      &:after{
          content: '';
          width: 100%;
          height: 5px;
          display: block;
          background: $gray;
          position: absolute;
          top: 11px;
          z-index: 0;
        }
  }

  .fw-wrapper.col-md-12, .fw-wrapper.col-md-6{
      .fw-box{
        flex-direction: row; 
        flex-wrap: wrap;
        padding-bottom: 0px;
        .fw-img{
         height: 240px;
          background: $brand-tertiary;
         img{
          transform: scale(1.3);
         }
        }
        .btn{
            left: 0;
            right: 0;
            margin: auto;
            height: 50px;
            padding: 16px 0 0 0;
          @include media-breakpoint-up(lg) {
            right: 13%; 
          }
        }
        @include hover-focus {
          @include transition-in-out(0.3s);
          .fw-img{
            img{
              transform: scale(1.35);
            }
          }
        }//end hover
      }
      
    }
  .fw-wrapper{
    text-align: center;
    color: $gray;
        @include media-breakpoint-down(md) {  
          margin: 0 0 40px 0;    
        }
    .fw-box{
      display: flex; 
      height: 100%;
      background:#fff;  
      padding-bottom: 20px;
      box-shadow: 0 0 25px rgba($gray, 0.7);
      flex-direction: column; 
      align-items: center;
      @include transition-in-out(0.3s);
      .fw-title{
        font-size: 1em;
        font-weight: 700;
        margin: 25px 0 15px 0;
        color: $gray;
        text-align: center;
      }
      .fw-text{
        padding: 10px 4% 15px;
        color: $brand-primary;
        font-size: 0.85em;
        text-align: left;
        display: block;
        width: 100%;
        ul{

          @include media-breakpoint-up(lg) {
            column-count: 2;
          }
        }
      }
      .fw-img{
        width: 100%;
        height: 210px;
        display: block;
        position: relative;
        overflow: hidden;
        background: $brand-tertiary;
        img{
          position: absolute;
          top: 0; 
          bottom: 0;
          right: 0;
          left: 0;
          width: 100%;
          margin: auto;
          transform: scale(1);
          filter: grayscale(100%);
          @include transition-in-out(0.3s);
        }
      }
      .btn{
          bottom: -18px;
          max-width: 200px;
          display: block;
          transform: scale(1);
          position: absolute;
      }
      @include hover-focus {
        text-decoration: none;
        box-shadow: 0 0 35px rgba($gray, 0.7); 
        @include transition-in-out(0.3s);
        .btn{
          transform: scale(1.02);
        }
        .fw-img{
          img{
            filter: grayscale(0%);
            transform: scale(1.05);
            @include transition-in-out(0.3s);

          }
        }
      }//end hover

    }//end box
    
  }
}

//FAQ accordion
.deal_accordion{
    .deals-wrapper{
      h3{
        font-size: 2em;
      }
      p{
        font-size: 0.9em;
      }
      h3, p{
      text-align: center;
      }
     
    }
    
    .panel-group{
      position: relative;
      margin-bottom: 0;
      .panel+.panel{
        margin: 0;
        padding: 0;
      }
      .panel{
        border-bottom: 3px solid #fff;
        &:first-of-type{
            .panel-heading{
            border-top: none;
            }
          }
        .panel-heading{
          border-radius: 0;
          display: block;
          &:hover, &:focus{
            text-decoration: none;
            .panel-title{
              background-color: $brand-primary;
            }
          }
          .panel-title{
            background-color: $brand-secondary;
            color: #fff;
            margin-bottom: 0;
            margin-top: 0;
            padding: 10px 3%;
            font-size: 1em;
            position: relative;
            z-index: 5;
            p{
              padding: 0;
              margin: 0;
              display: initial;
            }
          }
        }
  
        .panel-collapse{
          border-top: none;
          box-shadow: 0px 0 15px rgba(0,0,0, 0.6);     
          .panel-body{
            border: 0;
            padding: 12px 3%;
            font-size: 0.8em; 
            margin: 0;
                
          }
        }
      }
    }
  }

//EVENTS
.modules.upcoming_events{
  padding: 20px 0 50px;
  .events-wapper{
    h3{
      text-align: center;
      font-size: 2.5em;
      margin-bottom: 45px;
    }
  }
  .events{
    margin: 20px 0 0;
  }
}





//CALL TO ACTION
.modules.call_to_action{
  padding: 0;
  background: $brand_primary;
  .cta-content{
    padding: 40px 3%;
    h2{
      font-size: 1.8em;
      text-align: left;
     }
    h2, p{
      color: #fff;
    }
    .btn{
      margin: 10px;
    }
  }
  .actions{
    display: flex;
    flex-direction: column;
    justify-content: center;

    padding: 40px 3%;
    background: url('/wp-content/themes/chippers-lanes-2017/dist/images/wood-bg.jpg') center center no-repeat;
    background-size: cover;
    h4{
      color: #fff;
      font-weight: 700;
    }
    .btn{
      margin: 10px 10px 0 0;
    }
    .dropdown-menu{
      padding: 0;
      font-size: 0.9em;
      border: none;
      background: $brand-secondary;
      .dropdown-item{
        padding-top: 8px;
        padding-bottom: 8px;
        text-transform: uppercase;
        font-size: 0.8em;
        color: #fff;
        @include hover-focus {
          color: $gray;
        }
        &:last-of-type{
          border-bottom: none;
        }
      }
    }
  }
}

//single-image
.single-image{
  .single-img{
    position: relative;
    display: block;
    margin: 15px auto;
    overflow: hidden;
    .overlay{
      position: absolute;
      right: -36px;
      bottom: -60px;
      z-index: 10;
      height: 150px;
      width: 100px;
      background: rgba($brand-primary, 0.8);
      color: #fff;
      padding: 10px 0;
      font-size: 1.3em;
      transform: rotate(45deg);
      @include transition-in-out(0.3s);
      i{
        position: absolute;
        left: 24px;
        bottom: 63px;
        transform: rotate(-45deg);
      }
    }
    &:hover{
      .overlay{
        @include transition-in-out(0.3s);
        background: rgba($brand-primary, 1);

      }
    }
  }
}

//GALLERY
.image_gallery,{
  h3{
    color: $brand_primary;
    margin: 0 0 20px 0;
    font-size: 2.4em;
  }
  text-align: center;
  .gallery{
    position: relative;
    display: block;
    max-width: 385px;
    margin: 15px auto;
    overflow: hidden;
    .overlay{
      position: absolute;
      right: -36px;
      bottom: -60px;
      z-index: 10;
      height: 150px;
      width: 100px;
      background: rgba($brand-primary, 0.8);
      color: #fff;
      padding: 10px 0;
      font-size: 1.3em;
      transform: rotate(45deg);
      @include transition-in-out(0.3s);
      i{
        position: absolute;
        left: 24px;
        bottom: 63px;
        transform: rotate(-45deg);
      }
    }
    &:hover{
      .overlay{
        @include transition-in-out(0.3s);
        background: rgba($brand-primary, 1);

      }
    }
  }
}
.post{
	margin: 25px 0;
	h1{
		font-size: 2.5em;
	}
}
.archive-events{
	margin: 25px 0;
}
.events_post_type{
	margin-bottom: 25px;
}
/* ==========================================================================
Gravity Forms - use Bootstrap styling
========================================================================== */
$line-height-computed: 20px;
$border-radius-base: 0; 

.hidden_label {
  label {
    @extend .sr-only;
  }
}

.gform_wrapper {
    ul {
        @extend .list-unstyled;
    }

    li {
        @extend .form-group;
    }

    form {
        margin-bottom: 0;
    }

    .gfield_required {
        color: $state-danger-text;
        padding-left: 1px;
    }

    .ginput_container {
        input, select, textarea {
            @extend .form-control;
            border-radius: 0;
            background: lighten($white, 5%);
            &:focus{
               background: lighten($white, 15%); 
            }
        }

        select[multiple],
        select[size] {
            height: auto;
        }

        textarea {
            height: auto;
        }

        ul.gfield_checkbox input,
        ul.gfield_radio input {
            float: left;
            display: block;
            height: auto;
            width: auto;
            min-height: $line-height-computed;
            vertical-align: middle;
            position: absolute;
            line-height: normal;
        }

        ul.gfield_checkbox label,
        ul.gfield_radio label {
            margin-left: 20px;
        }

        .datepicker_no_icon {
            font-family: 'FontAwesome', $font-family-base;
        }

        .ginput_left {
            @include make-col(6);
            @extend .first !optional;
        }

        .ginput_right {
            @include make-col(6);
            @extend .last !optional;
        }

        .ginput_full,
        .gf_clear {
            @extend .clearfix;
        }

    }

    .gfield_date_month,
    .gfield_date_dropdown_month {
        @include make-col(4);
        @extend .first !optional;
    }

    .gfield_date_day,
    .gfield_date_dropdown_day {
        @include make-col(4);
    }

    .gfield_date_year,
    .gfield_date_dropdown_year {
        @include make-col(4);
        @extend .last !optional;
    }

    .gform_button {
        @extend .btn;
        @extend .btn-primary;
    }

    .gfield_error {
        .gfield_label {
            color: $state-danger-text;
        }

        input, select, textarea {
            border-color: $alert-danger-border;
            background-color: $alert-danger-bg;
            color: $alert-danger-text;
            
        }
    }

    .validation_error {
        @extend .alert;
        @extend .alert-danger;
    }

    #gforms_confirmation_message {
        @extend .alert;
    }

    .gform_validation_container {
        @extend .hidden !optional;
    }
}
                

@include media-breakpoint-down(sm) {
    .gform_wrapper {
        .col-sm-2,
        .col-sm-3,
        .col-sm-4,
        .col-sm-6 {
            margin-left: 0;
            margin-right: 0;
            padding-left: 0;
            padding-right: 0;
        }

        .gfield_date_month,
        .gfield_date_day,
        .gfield_date_year,
        .gfield_date_dropdown_month,
        .gfield_date_dropdown_day,
        .gfield_date_dropdown_year {
            margin-left: 0;
            margin-right: 0;
            padding-left: 0;
            padding-right: 0;
        }

        .gfield_date_dropdown_month,
        .gfield_date_dropdown_day {
            margin-bottom: 10px;
        }
    }
}



// Datepicker field
.ui-datepicker {
    width: 17em;
    padding: .2em .2em 0;
    display: none;
    background-color: $dropdown-bg;
    border: 1px solid $dropdown-bg;
    border-radius: $border-radius-base;
    @include box-shadow(0 6px 12px rgba(0,0,0,.175));


    .ui-datepicker-header {
        position:relative;
        padding:.2em 0;
        border:0px;
        font-weight: bold;
        width: 100%;
        padding: 4px 0;
        background-color: $body-bg;
        color: $brand-primary;
    }
    .ui-datepicker-prev,
    .ui-datepicker-next {
        position:absolute;
        top: 2px;
        width: 1.8em;
        height: 1.8em;
    }

    .ui-datepicker-prev-hover,
    .ui-datepicker-next-hover {
        /*top: 1px;*/
    }
    .ui-datepicker-prev { left:2px; }
    .ui-datepicker-next { right:2px; }

    .ui-datepicker-prev-hover { /*left:1px;*/ }
    .ui-datepicker-next-hover { /*right:1px;*/ }

    .ui-datepicker-prev span,
    .ui-datepicker-next span {
        display: block;
        font-size: 11px;
        margin-top: -7px;
        position: absolute;
        top: 50%;
        &:hover {
            cursor: pointer;
        }
    }
    .ui-datepicker-title {
        margin: 0 2.3em;
        line-height: 1.8em;
        text-align: center;
        select {
            font-size:1em;
            margin:1px 0;
        }
    }
    select.ui-datepicker-month-year {
        width: 100%;
    }
    select.ui-datepicker-month, select.ui-datepicker-year {
        width: 49%;
    }
    table {
        width: 100%;
        font-size: .9em;
        border-collapse: collapse;
        margin:0 0 .4em;
    }
    th {
        padding: .7em .3em;
        text-align: center;
        font-weight: bold;
        border: 0;
    }
    td {
        border: 0;
        padding: 1px;
        span, a {
            display: block;
            padding: .2em;
            text-align: right;
            text-decoration: none;
        }
    }
    .ui-datepicker-buttonpane {
        background-image: none;
        margin: .7em 0 0 0;
        padding:0 .2em;
        border-left: 0;
        border-right: 0;
        border-bottom: 0;
        button {
            float: right;
            margin: .5em .2em .4em;
            cursor: pointer;
            padding: .2em .6em .3em .6em;
            width:auto;
            overflow:visible;
            .ui-datepicker-current {
                float:left;
            }
        }
    }
}

/* with multiple calendars */
.ui-datepicker.ui-datepicker-multi { width:auto; }
.ui-datepicker-multi .ui-datepicker-group { float:left; }
.ui-datepicker-multi .ui-datepicker-group table {
    width:95%;
    margin:0 auto .4em;
}
.ui-datepicker-multi-2 .ui-datepicker-group { width:50%; }
.ui-datepicker-multi-3 .ui-datepicker-group { width:33.3%; }
.ui-datepicker-multi-4 .ui-datepicker-group { width:25%; }
.ui-datepicker-multi .ui-datepicker-group-last .ui-datepicker-header { border-left-width:0; }
.ui-datepicker-multi .ui-datepicker-group-middle .ui-datepicker-header { border-left-width:0; }
.ui-datepicker-multi .ui-datepicker-buttonpane { clear:left; }
.ui-datepicker-row-break {
    clear:both;
    width:100%;
    font-size:0em;
}

/* RTL support */
.ui-datepicker-rtl {
    direction: rtl;
    .ui-datepicker-prev {
        right: 2px;
        left: auto;
        &:hover {
            right: 1px;
            left: auto;
        }
    }
    .ui-datepicker-next {
        left: 2px;
        right: auto;
        &:hover {
            left: 1px;
            right: auto;
        }
    }
    .ui-datepicker-buttonpane {
        clear:right;
        button {
            float: left;
            .ui-datepicker-current {
                float:right;
            }
        }
    }
    .ui-datepicker-group {
        float:right;
    }
    .ui-datepicker-group-last .ui-datepicker-header {
        border-right-width:0;
        border-left-width:1px;
    }
    .ui-datepicker-group-middle .ui-datepicker-header {
        border-right-width:0;
        border-left-width:1px;
    }
}

/* IE6 IFRAME FIX (taken from datepicker 1.5.3 */
.ui-datepicker-cover {
    display: none; /*sorry for IE5*/
    //display/**/: block; /*sorry for IE5*/
    position: absolute; /*must have*/
    z-index: -1; /*must have*/
    filter: mask(); /*must have*/
    top: -4px; /*must have*/
    left: -4px; /*must have*/
    width: 200px; /*must have*/
    height: 200px; /*must have*/
}

.ui-datepicker th {
    font-weight: bold;
    color: gray;
}

.ui-datepicker-today {
    a {
        background-color: $link-color;
        cursor: pointer;
        padding: 0 4px;
        margin-bottom:0px;
        &:hover {
            background-color: $gray;
            color: $gray-lighter;
        }
    }
}

.ui-datepicker td {
    a {
        margin-bottom:0px;
        border:0px;
    }

    &:hover {
        color:$gray-lighter;
    }

    .ui-state-default {
        border: 0;
        background:none;
        margin-bottom: 0;
        padding: 5px;
        color: gray;
        text-align: center;
        filter: none;
        &:hover {
            background:$link-color;
            color:$gray-lighter;
            border-radius: 4px;
        }
    }

    .ui-state-highlight {
        color: #404040;
        background: #eedc94;
        text-shadow: 0 -1px 0 rgba(0, 0, 0, 0.25);
        border-color: #eedc94 #eedc94 #e4c652;
        border-color: rgba(0, 0, 0, 0.1) rgba(0, 0, 0, 0.1) rgba(0, 0, 0, 0.25);
        border-radius: 4px;
    }

    .ui-state-active {
        background:$gray-light;
        margin-bottom:0px;
        font-size:normal;
        text-shadow: 0px;
        color:$gray-lighter;
        border-radius: 4px;
    }
}

.gform_wrapper {
  .has_first_name.has_last_name {
        .name_first {
            @include make-col(12);
            @extend .first !optional;
            margin-bottom: 15px;
            padding-left: 0;
        }

        .name_last {
            @include make-col(12);
            @extend .last !optional;
            margin-bottom: 15px;
            padding-right: 0;
        }
    }

    @media (min-width: 768px ) {
        .gf_list_2col {
            .gfield_checkbox,
            .gfield_radio {
                li {
                    float: left;
                    width: 50%;

                    &:nth-child(odd) {
                        clear: both;
                    }
                }
            }
        }
    }

    .gform_footer {
        .gform_button {
            @extend .btn-primary;
            @extend .button-variant;
            
        }
    }

    input::-webkit-input-placeholder,
    textarea::-webkit-input-placeholder {
    }

    input:-moz-placeholder,
    textarea:-moz-placeholder { /* Firefox 18- */
    }

    input::-moz-placeholder,
    textarea::-moz-placeholder {  /* Firefox 19+ */
    }

    input:-ms-input-placeholder,
    textarea:-ms-input-placeholder {
    }
}

// Helpers

// Grid settings
$main-sm-columns:       12;
$sidebar-sm-columns:    4;


//transition mixin
@mixin transition-in-out($timeing) {
  transition: all $timeing ease-in-out;
}
//transition mixin
@mixin transition-in-out-delay($timeing, $delay) {
  -moz-transition: all $timeing $delay ease-in-out;
  -o-transition: all $timeing $delay ease-in-out;
  -webkit-transition: all $timeing $delay ease-in-out;
  transition: all $timeing $delay ease-in-out;
}
//scale mixin
@mixin scale($size) {
  -moz-transform: scale($size, $size);
  -ms-transform: scale($size, $size);
  -webkit-transform: scale($size, $size);
  transform: scale($size, $size);
}


//Ken Burns effect
$items: 1;
$animation-time: 25s;
$transition-time:25s;
$scale: 10%;

$total-time: ($animation-time * $items);
$scale-base-1: (1 + $scale / 100%);

@keyframes kenburns {
	20% {
	    transform: scale(1);
	}
	100% {
	    transform: scale(1.1);
	}
}


// Glyphicons font path
$icon-font-path:        "../fonts/";


// Primary Colors
$brand-primary:         #e4701d; 
$brand-secondary: #0064aa; 
$brand-tertiary: #4b4b4b; 
$gray-light: #f3f3f3;




//fonts
$font-size-base: 1.25rem;
$font-family-base: 'Montserrat', sans-serif;;
$font-family-second: 'Montserrat', sans-serif;;
$font-size-h1: 3.5em;
$font-size-h2: 2.5em;
$font-size-h3: 1.6em;
$font-size-h4: 1.6em;



//nav
$border-radius: 0;
$navbar-light-color:$brand-tertiary; 
$navbar-light-active-color: $brand-primary;
$navbar-light-hover-color: $brand-primary;


//dropdowns
$dropdown-bg: $brand-primary;
$dropdown-link-hover-bg: transparent;
$dropdown-link-color: #fff;
$dropdown-link-active-color: #ffffff;
$dropdown-link-active-bg: transparent;
$dropdown-link-hover-color: $brand-secondary;
$dropdown-caret-color:#ffffff;



//links and buttons
$link-color: $brand-secondary;
$link-hover-color: $brand-secondary;


//breadcrumbs
$breadcrumb-divider: '>';
$breadcrumb-bg: transparent;
$breadcrumb-color: #000000 ;
$breadcrumb-active-color: #000000;



//vars not in bootstrap 3.3.7
$spacer: 1rem;
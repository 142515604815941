//PAGE HEADER
.page-header{
	margin: 15px 0;
	h1{
		@extend .container;
		text-align: center;
	}
}


//BREADCRUMBS
.breadcrumb-block{
	margin: 0 auto;
	.breadcrumb{
		box-sizing: border-box;
		border-radius: 0;
		font-size: 0.8em;
		padding: 10px 15px;
		li{
			color: $brand-tertiary;
			a{
				color: $brand-secondary;
				text-decoration: none;
			}
		}
		&>li+li:before{
			text-decoration: none;
		}
	}
	.breadcrumb-item+.breadcrumb-item:before{
		color: $brand-tertiary;
	}
}


a{
  @include transition-in-out(0.3s);

  @include hover-focus {
     @include transition-in-out(0.3s);
	text-decoration: none;
  }
}

h1{
	color: $brand-primary;
	font-weight: 700;
	text-transform: uppercase;
	@include media-breakpoint-down(xs) {
		font-size: 12vw;
	}
}
h2{
	@include media-breakpoint-down(xs) {
		font-size: 10vw;
	}
}
h2, h3{
	font-weight: 700;
	text-transform: uppercase;
}
h4{
	color: $brand-primary;
	text-transform: uppercase;
	font-weight: 400;
}

.bg-primary{
	background-color: $gray-light !important;
}
 footer{
 	background: url('/wp-content/themes/chippers-lanes-2017/dist/images/footer-bg.jpg') center center no-repeat;
    background-size: cover;
    color: #fff;
    text-align: center;
    padding: 50px 0;
    h5{
    	font-size: 1.1em;
    	margin: 0.6em 0;
    }
    p{
    	font-size: 0.6em;
    	margin: 0.3em 0;
    }
    a{
    	color: #fff;
    }
    .footer-social{
    	font-size: 2.1em;
    	a{
    		margin: 0 3px;
    	}
    }
 }   


/*

Create custom button using:
@mixin button-variant($color, $background, $border)

e.g.
.custom-btn {
  @include button-variant(#cece19, #000000, #2dbe27);
}

*/

// Custom padding and font sizes

.btn-primary {
	font-size: 0.8em;
	font-weight: 400;
  text-transform: uppercase;
	padding: 15px 4.8em 15px 2.2em;
	border-radius: 0px;
	border: 0;
	margin: 10px 0px;
  border-radius: 0;
  background: $brand-secondary;
  position: relative;
  overflow: hidden;
    &:before{
      content: '\F061';
      font: normal normal normal 14px/1 FontAwesome;
      text-rendering: auto;
      -webkit-font-smoothing: antialiased;
      margin: auto;
      position: absolute;
      font-size: 1em;
      right: 1.1em;
      color: #fff;
      z-index: 9;
      padding: 3px 0;
      transform: scale(1);
    }
    &:after{
      width: 6.5em;
      height: 200%;
      background: rgba(255,255,255, 0.1);
      font: normal normal normal 14px/1 FontAwesome;
      text-rendering: auto;
      -webkit-font-smoothing: antialiased;
      z-index: 1;
      right: -2em;
      top: -1em;

      transform-origin: 0 0;
      transform: rotate(-20deg) translate(0, 0);
      content: '';
      position: absolute;
      z-index: 0;
    }
    @include hover-focus {
      border: 0;
      box-shadow: none;
      color: #ffffff;
      background-color: darken($brand-secondary, 10%);
      &:before{
        @include transition-in-out(0.3s);
        transform: scale(1.09);

      }
      &:after{
        @include transition-in-out(0.3s);
        transform: rotate(-20deg) translate(-8px, 0);       
      }
      
    }
}


.button-variant{
  padding: 12px 1.7em 12px ;
  &:before, &:after{
    display: none;
  }
}

.btn-secondary{
  @extend .btn-primary;
  background: $gray-light;
  border: 2px solid $brand-secondary;
  color: $brand-secondary;
  text-align: left;
  text-transform: none;
  padding: 13px 4em 13px 1em;
  font-weight: 700;
  &:before{
      content: '\F107';
      font: normal normal normal 30px/1 FontAwesome;
      line-height: 0.5em;
      width: 0px;
    }
    &:after{
      right: -3.1em;
      background: $brand-secondary;
      transform-origin: 0 0;
      transform: rotate(0deg) translate(0, 0);
    }
  @include hover-focus {
      border: 2px solid $brand-secondary;
      color: $brand-secondary;
      box-shadow: none;
      background-color: darken($gray-light, 10%);
      &:before{
        @include transition-in-out(0.3s);
        transform: scale(1.15);
      }
      &:after{
        @include transition-in-out(0.3s);
        transform: rotate(0deg) translate(0px, 0);       
      }
    }

}

.btn-ribbon{
  background: $brand-primary;
  position: relative;
  border: 0;
  font-size: 0.7em;
  text-transform: uppercase;
  color: #fff;
  padding: 10px 38px;
  background: url('/wp-content/themes/chippers-lanes-2017/dist/images/ribbon.svg') center center no-repeat;
  background-size: 100%;
}

.btn-default,  .button-variant, .btn-lg{
    @extend .btn-primary;
}

.btn-lg {
  font-size: 1.8em;
}







//transition for buttons
.btn-default, .btn-primary, .btn-lg, .button-variant{
		@include transition-in-out(0.3s);
    &:before, &:after{
      @include transition-in-out(0.3s);
    }
	&:hover, &:active {
		@include transition-in-out(0.3s);
    &:before, &:after{
      @include transition-in-out(0.3s);
    }
	}
}

#nav-link-bar{
	background: $brand-primary;
	display: block;
	position: absolute;
	height: 4px;
	transform: translate(0, 243px);
    @include transition-in-out(0.3s);
	@include media-breakpoint-down(md) {
		display: none;
	}
	
}
.admin-bar #nav-link-bar{
    top: -32px;
}
header{
	&.navbar{
		padding: 0;	
		position: relative;
		z-index: 99;
	}
	.navbar{
		padding: 0;
   		@include media-breakpoint-down(md) {
			min-height: 57px;
   		}
	}
	.top-navbar{
		@extend .container;	
		text-align: center;
		padding-top: 20px;
		padding-bottom: 20px;
		div{
			display: flex;
			justify-content: center;
    		align-items: center;
		}
   		@include media-breakpoint-down(md) {
			.middle{
				order: -1;
			}
   		}


	}
	//LOGO
	.navbar-brand{
		width: 100%;
		height: 155px;
		max-width: 282px;
		min-width: 155px;

	}
	.menu-main-navigation-container{
		@extend .container;	
   			@include media-breakpoint-down(md) {
				margin-top: 57px;
			}
	}

	.navbar-toggler{
		z-index: 10;
	}
	.navbar-nav{
		justify-content: space-between;
	}
	.navbar{
		background: rgba($brand-tertiary, 0.7);
		width: 100%;
		.navbar-toggler{
			border: 1px solid #fff;
			background: $brand-secondary;
			margin: 8px 0 ;
		}
		.navbar-toggler-text{
			line-height: 2em;
		    font-size: 0.8em;
		    text-transform: uppercase;
		    color: #fff;
		}
		.nav-item{
   			padding: 0 20px;
   			position: relative;
   			width: auto;
   			display: inline-block;
   			@include media-breakpoint-up(lg) {
				text-align: center;
			}
			.nav-link{
				text-transform: uppercase;
				padding: 0.8em 5px;
		    	font-size: 0.8em;
		    	color: #fff;

			}
			
		}

		.dropdown-menu{
			padding: 0;
			font-size: 0.9em;
			border: none;
			background: rgba($brand-primary, 0.9);
			.dropdown-item{
				padding-top: 8px;
				padding-bottom: 8px;
				text-transform: uppercase;
				font-size: 0.8em;
				color: $gray;
				@extend .col-12;
   				@include media-breakpoint-up(lg) {
					@extend .col-lg-6;
   				}

			&.active{
				color: #fff;
			}
			@include hover-focus {
				color: #fff;
			}
			&:last-of-type{
				border-bottom: none;
			}
		}
		}
		.dropdown.show{
			.dropdown-menu{
				@extend .row;
				@include media-breakpoint-up(lg) {
					margin: 0;
					width: 51vw;
					max-width: 550px;
				}
			}
		}
		.nav-item {
			&:nth-last-child(-n+3){
				.dropdown-menu{
					@include media-breakpoint-up(lg) {
						margin: 0;
						width: 150%;
					}
					.dropdown-item{
						@extend .col-12;
					}
				}
			}
		}

	}
}

